<template>
  <div class="content-div">
    <h1>Application Procedures</h1>
    <h3 class="em-34">1. Document Preparation</h3>
    <BoldLine class="mt-10" :options="{color:'#CEC3F0',height:'3px',width:'100%'}"></BoldLine>
    <v-expansion-panels class="mb-8" multiple > 
      <OlsExpansion :order="special.order" title="Liquor Licence" :type="1">
        <p style="max-width:700px"><strong>Each applicant is required to complete one copy of an application form and return it with the following documents:</strong></p>
        <ol type="a" class="expansion">
          <li><p>a copy of the applicant's HKID Card;</p></li>
          <li><p>a copy of each of the business registration certificate of the restaurant and company (if the establishment under application is a limited company); </p></li>
          <li><p>2 recent photographs (35mm x 40mm) of the applicant ;</p></li>
          <li><p>3 copies of a plan showing the layout of the premises with clear indication of the area used for dancing (for applications with dancing endorsement only);</p></li>
          <li><p>a copy of the certificate of incorporation if the owner of the establishment under application is a corporation;</p></li>
          <li><p>a copy of the full or provisional general/light refreshment/marine restaurant licence, if available,</p></li>
          <li><p>a copy of the business registration certificate bearing the new shop sign (for change of shop sign of the establishment only);</p></li>
          <li><p>a copy of the amended business registration certificate in respect of the premises under application (for amendment of size of licensed premises); and</p></li>
          <li>
            <p>3 copies of a plan showing the layout of the premises with clear indication of the area included/deleted (for amendment of size of licensed premises).</p>
            <p class="mt-6"><strong>For liquor licence application in respect of the premises not under application for or covered by Restaurant Licence or Certificate of Compliance, please submit the following documents:</strong></p>
          </li>
          <li><p>5 copies of identical proposed layout plans are required to show the layout of the premises including the portion for sale or supply of liquor and for consumption of liquor on the premises. Such plans should be drawn to scale (of not less than 1:100) in metric units;</p></li>
          <li><p>3 copies of identical proposed ventilating system layout plans together with the Supplier Certificate are required if the ventilating system is installed in the premises. Such plans should be drawn to scale (of not less than 1:100) in metric units; and</p></li>
          <li><p>3 copies of identical location maps drawn to scale of 1:1000, in metric units, are required to clearly indicate the location of the premises.</p></li>
        </ol>
      </OlsExpansion>
      <OlsExpansion :order="special.order" title="Club Liquor Licence" :type="1">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </OlsExpansion>
    </v-expansion-panels>
    <h3 class="em-34 mt-16">2. Processing of the Application</h3>
    <BoldLine class="mt-10" :options="{color:'#CEC3F0',height:'3px',width:'100%'}"></BoldLine>
    <v-expansion-panels class="mb-8" multiple > 
      <OlsExpansion :order="special.order" title="New Issue of Licence" :type="1">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </OlsExpansion>
      <OlsExpansion :order="special.order" title="Renewal & Amendment(s) of Licence" :type="1">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </OlsExpansion>
      <OlsExpansion :order="special.order" title="Transfer of Liquor Licence" :type="1">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </OlsExpansion>
      <OlsExpansion :order="special.order" title="Reserve Licensee Mechanism" :type="1">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </OlsExpansion>
      <OlsExpansion :order="special.order" title="Temporary absence of Licensee" :type="1">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </OlsExpansion>
    </v-expansion-panels>
    <h3 class="em-34 mt-16">3. Licensing Conditions</h3>
    <BoldLine class="mt-10" :options="{color:'#CEC3F0',height:'3px',width:'100%'}"></BoldLine>
    <v-expansion-panels class="mb-8" multiple > 
      <OlsExpansion :order="special.order" title="Liquor Licence" :type="1">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </OlsExpansion>
      <OlsExpansion :order="special.order" title=" Club Liquor Licence" :type="1">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </OlsExpansion>

    </v-expansion-panels>
    
  </div>
  
</template>

<script>
import BoldLine from '../../components/misc/bold-line'
import OlsExpansion from '../../components/misc/ols-expansion'

export default {
  name: 'InProcedures',
  components: {
    BoldLine,
    OlsExpansion,
  },
  data: function () {
    return {
      items:[
          
      ],
      special:{
          order: 11,
          title:'I cannot use PPS for online payment recently. I have been using PPS payment in the past. Why does this happen and what can I do?',
          content:'<img :src="logo" alt="Error message in Internet Explorer" width="234" height="294">'
      },
    }
  },
}
</script>

<style lang="scss">
p.expansion{
  strong.i{
       font-style: italic;
  }
  span{
    word-wrap: break-word;
  }
}
a{
      text-decoration: none !important;
    p{
      color:$ols-primary !important;
    }
    line-break: anywhere;
}

.s-li{
    display: flex;
    align-items: flex-start;
    margin-bottom:12px;
    div:first-child{
        color: $ols-secondary;
        font-weight: bold;
        font-size:2em;
        margin:-6.5px 16px 0px 20px;
    }
}

.content-div h3{
  font-size:2.125em;
}

ol,.expansion ul.expansion{
  margin-left:140px;
  
  li{
    margin-bottom:12px;
    p{
      margin:0px;
      span{
        word-wrap: break-word;
      }
    }
  }
}

img.expansion{
  margin-left:60px;
  margin-bottom:16px;
}
//md and down
@media screen and (max-width: 1263px) {
  

}

</style>